<template>
  <div>
    <v-list>
      <v-list-item-group v-model="relatedParesOfSelected" multiple>
        <template v-for="par in paresAlreadyAddedInDiagnose">
          <v-list-item :key="par.id" :value="par.id">
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" color="primary"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ par.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ par.categories }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ParesAlreadyInDiagnose",

  computed: {
    ...mapState({
      paresAlreadyAddedInDiagnose: (state) =>
        state.checkCategories.paresAlreadyAddedInDiagnose,
    }),

    relatedParesOfSelected: {
      get() {
        return this.selectedType === "tumoralPhenomena"
          ? this.relatedParesOfSelectedTumoralPhenomena
          : this.relatedParesOfSelectedPotentialSpaces;
      },
      set(value) {
        if (this.selectedType === "tumoralPhenomena") {
          this.relatedParesOfSelectedTumoralPhenomena = value;
        } else {
          this.relatedParesOfSelectedPotentialSpaces = value;
        }
      },
    },

    relatedParesOfSelectedTumoralPhenomena: {
      set(value) {
        this.$store.commit("tumoralPhenomena/setRelatedParesOfSelected", value);
      },
      get() {
        return this.$store.state.tumoralPhenomena.relatedParesOfSelected;
      },
    },

    relatedParesOfSelectedPotentialSpaces: {
      set(value) {
        this.$store.commit("potentialSpaces/setRelatedParesOfSelected", value);
      },
      get() {
        return this.$store.state.potentialSpaces.relatedParesOfSelected;
      },
    },

    selectedType: {
      get() {
        return this.$store.state.checkCategories.selectedType;
      },
    },
  },
};
</script>

<style scoped></style>
